import { toast } from 'react-toastify';

import { apiSlice } from '../apiSlice';
import axios from '../../utils/axios';

export const tags = {
  orgDetails: 'orgDetails',
  lockDeskSettings: 'lockDeskSettings',
};

const organizationApi = apiSlice
  .enhanceEndpoints({ addTagTypes: Object.values(tags) })
  .injectEndpoints({
    endpoints: (build) => ({

      // Get Organization Details
      getOrganizationDetails: build.query({
        queryFn: async (_, { forced }) => {
          try {
            const response = await axios({
              url: `${baseURL}/organization`,
              method: 'GET',
              forced,
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error: error.message };
          }
        },
        providesTags: [tags.orgDetails],
      }),



      // Update Organization
      updateOrganization: build.mutation({
        queryFn: async (payload) => {
          try {
            const response = await axios({
              url: `${baseURL}/organization`,
              method: 'PUT',
              data: payload,
            });
            toast.success('Updated');
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong.');
            return { error: error.message };
          }
        },
        invalidatesTags: [tags.orgDetails, tags.lockDeskSettings],
      }),



      // Update Organization Logo
      updateOrganizationLogo: build.mutation({
        queryFn: async ({ file, cb }) => {
          try {
            const formData = new FormData();
            formData.append('file', file);
            const response = await axios({
              url: `${baseURL}/organizations/logo`,
              method: 'PUT',
              data: formData,
            });
            cb();
            toast.success('Updated');
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong.');
            return { error: error.message };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.orgDetails]),
      }),



      // Update Organization Theme
      updateOrganizationTheme: build.mutation({
        queryFn: async ({ data, cb }) => {
          try {
            const response = await axios({
              url: `${baseURL}/organizations/theme`,
              method: 'PUT',
              data,
            });
            cb();
            toast.success('Updated');
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong.');
            return { error: error.message };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.orgDetails]),
      }),



      // Get Lock Desk Settings
      getLockDeskSettings: build.query({
        queryFn: async (_, { forced }) => {
          try {
            const response = await axios({
              url: `${baseURL}/organizations/lock-desk`,
              method: 'GET',
              forced,
            });
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            return { error: error.message };
          }
        },
        providesTags: [tags.lockDeskSettings],
      }),



      // Update Lock Desk Settings
      updateLockDeskSettings: build.mutation({
        queryFn: async (payload) => {
          try {
            const response = await axios({
              url: `${baseURL}/organizations/lock-desk`,
              method: 'PUT',
              data: payload.data,
            });
            payload.cb();
            toast.success('Updated');
            return { data: response.data };
          } catch (error) {
            console.error(error.response);
            toast.error('It looks like something went wrong.');
            return { error: error.message };
          }
        },
        invalidatesTags: (result, error) => (error ? [] : [tags.lockDeskSettings]),
      }),

    }),
  });



export const {
  useGetOrganizationDetailsQuery,
  useUpdateOrganizationMutation,

  useUpdateOrganizationLogoMutation,
  useUpdateOrganizationThemeMutation,

  useGetLockDeskSettingsQuery,
  useUpdateLockDeskSettingsMutation,
} = organizationApi;
