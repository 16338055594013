import React from 'react';
import { createRoot } from 'react-dom/client';

// import { ScrollToTopButton } from 'bumblebee-ui';
import ls from 'local-storage';
import { DndProvider } from 'react-dnd';
import { TouchBackend } from 'react-dnd-touch-backend';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { login } from './redux/reducers/auth';
import createStore from './redux/store';
import AppRouter from './routers/AppRouter';
import './styles/styles.scss';

export const store = createStore();

const user = ls('user');
if (user) {
  console.log('User: ', user);
  store.dispatch(login(user));
}

const organizationBranding = ls('organizationBranding');
if (organizationBranding?.theme) {
  const htmlTag = document.documentElement;
  htmlTag.setAttribute('data-bs-theme', organizationBranding.theme);
}

const jsx = (
  <Provider store={store}>
    <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
      <ToastContainer
        position='top-right'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
        pauseOnHover />

      <AppRouter />
      {/* <ScrollToTopButton handleClick={() => {
      window.scrollTo(0, 0);
    }} /> */}
    </DndProvider>
  </Provider>
);

const container = document.getElementById('app');
const root = createRoot(container);
root.render(jsx);
