import React, { Suspense } from 'react';
// import PropTypes from 'prop-types';

// import { Loading, NotFoundPage } from 'bumblebee-ui';
import { Loading } from 'murder-hornet-ui';
import { createBrowserHistory as createHistory } from 'history';
import { Route, Routes } from 'react-router-dom';

import {
  AppWrapper,
  CustomRouter,
  lazyWithRetry,
  PrivateRoute,
  PublicRoute,
  ScrollToTop,
} from './helpers';

// LANDING
const HomePage = lazyWithRetry(() => import('../components/HomePage'));

// AUTH
const LogIn = lazyWithRetry(() => import('../components/auth/LogIn'));
const Register = lazyWithRetry(() => import('../components/auth/Register'));
const ValidateMFA = lazyWithRetry(() => import('../components/auth/ValidateMFA'));
const ForgotPassword = lazyWithRetry(() => import('../components/auth/ForgotPassword'));
const ResetPassword = lazyWithRetry(() => import('../components/auth/ResetPassword'));

// DASHBOARD
const Dashboard = lazyWithRetry(() => import('../components/Dashboard'));

// APPLICATION PIPELINE
const AllApplications = lazyWithRetry(() => import('../components/application-pipeline/AllApplications'));

const ViewApplication = lazyWithRetry(() => import('../components/application-pipeline/ViewApplication'));

// MY SETTINGS
const IndexMySettings = lazyWithRetry(() => import('../components/settings/my-settings/IndexMySettings'));
const PersonalInformation = lazyWithRetry(() => import('../components/settings/my-settings/PersonalInformation'));
const ResetPasswordLoggedIn = lazyWithRetry(() => import('../components/settings/my-settings/ResetPassword'));
const Sessions = lazyWithRetry(() => import('../components/settings/my-settings/Sessions'));
const Notifications = lazyWithRetry(() => import('../components/settings/my-settings/Notifications'));


// USERS
const IndexUsers = lazyWithRetry(() => import('../components/settings/users/IndexUsers'));
const Teams = lazyWithRetry(() => import('../components/settings/users/Teams'));
const AllUsers = lazyWithRetry(() => import('../components/settings/users/AllUsers'));
const ViewUser = lazyWithRetry(() => import('../components/settings/users/ViewUser'));
const UserDetail = lazyWithRetry(() => import('../components/settings/users/ViewUser'));
const InviteUser = lazyWithRetry(() => import('../components/settings/users/InviteUser'));
const UserPermissionGroups = lazyWithRetry(() => import('../components/settings/users/UserPermissionGroups'));

// HELP
const Help = lazyWithRetry(() => import('../components/help/Help'));

// RELEASE NOTES
const ReleaseNotes = lazyWithRetry(() => import('../components/settings/help/release-notes/ReleaseNotes'));
const ViewReleaseNote = lazyWithRetry(() => import('../components/settings/help/release-notes/ViewReleaseNote'));

// HELP TICKETS
// const HelpTickets = lazyWithRetry(() => import('../components/help/help-tickets/HelpTickets'));
// const CreateHelpTicket = lazyWithRetry(() => import('../components/help/help-tickets/CreateHelpTicket'));

// const ViewHelpTicket = lazyWithRetry(() => import('../components/help/help-tickets/ViewHelpTicket'));
// const HelpTicketDetails = lazyWithRetry(() => import('../components/help/help-tickets/tabs/HelpTicketDetails'));
// const HelpTicketCommunications = lazyWithRetry(() => import('../components/help/help-tickets/tabs/HelpTicketCommunications'));


// TEST
const TestPage = lazyWithRetry(() => import('../components/TestPage'));



export const history = createHistory();

const AppRouter = () => (
  <CustomRouter history={history}>
    <ScrollToTop>
      <div className='container-scroller'>

        <AppWrapper>
          <Suspense fallback={<Loading main />}>
            <Routes>
              {/* LANDING */}
              <Route path='/' element={<PublicRoute><HomePage /></PublicRoute>} />

              {/* AUTH */}
              <Route path='/login/:organization_uuid' element={<PublicRoute><LogIn /></PublicRoute>} />
              <Route path='/register/:organization_uuid/:token_uuid' element={<PublicRoute><Register /></PublicRoute>} />
              <Route path='/validate/session/:organization_uuid' element={<PublicRoute><ValidateMFA /></PublicRoute>} />
              <Route path='/forgot-password/:organization_uuid' element={<PublicRoute><ForgotPassword /></PublicRoute>} />
              <Route path='/reset-password/:organization_uuid/:token_uuid' element={<PublicRoute><ResetPassword /></PublicRoute>} />

              {/* DASHBOARD */}
              <Route path='/dashboard' element={<PrivateRoute><Dashboard /></PrivateRoute>} />

              {/* APPLICATIONS */}
              <Route path='/applications' element={<PrivateRoute><AllApplications /></PrivateRoute>} />
              <Route path='/applications/:application_uuid/:layout?' element={<PrivateRoute><ViewApplication /></PrivateRoute>} />

              {/* MY SETTINGS */}
              <Route path='/my-settings'>
                <Route index element={<PrivateRoute><IndexMySettings /></PrivateRoute>} />
                <Route path='personal-information' element={<PrivateRoute><PersonalInformation /></PrivateRoute>} />
                <Route path='reset-password' element={<PrivateRoute><ResetPasswordLoggedIn /></PrivateRoute>} />
                <Route path='sessions' element={<PrivateRoute><Sessions /></PrivateRoute>} />
                <Route path='notifications' element={<PrivateRoute><Notifications /></PrivateRoute>} />
              </Route>


              {/* USERS */}
              <Route path='users'>
                <Route index element={<PrivateRoute><IndexUsers /></PrivateRoute>} />
                <Route path=':user_uuid' element={<PrivateRoute><UserDetail /></PrivateRoute>} />
                <Route path='teams' element={<PrivateRoute><Teams /></PrivateRoute>} />
                <Route path='all-users' element={<PrivateRoute><AllUsers /></PrivateRoute>} />
                <Route path='all-users/:user_lender_uuid' element={<PrivateRoute><ViewUser /></PrivateRoute>} />
                <Route path='invite-user' element={<PrivateRoute><InviteUser /></PrivateRoute>} />
                <Route path='user-permissions' element={<PrivateRoute><UserPermissionGroups /></PrivateRoute>} />
              </Route>

              {/* HELP */}
              <Route path='help'>
                <Route index element={<Help />} />

                {/* RELEASE NOTES */}
                <Route path='release-notes' element={<PrivateRoute><ReleaseNotes /></PrivateRoute>} />
                <Route path='release-notes/:release_note_uuid' element={<PrivateRoute><ViewReleaseNote /></PrivateRoute>} />

                {/* HELP TICKETS */}
                {/* <Route path='help-tickets' element={<PrivateRoute><HelpTickets /></PrivateRoute>} />
                <Route path='help-tickets/new' element={<PrivateRoute perform='lender:special:test'><CreateHelpTicket /></PrivateRoute>} />
                <Route path='help-tickets/:help_ticket_uuid' element={<PrivateRoute><ViewHelpTicket /></PrivateRoute>}>
                  <Route path='details' element={<HelpTicketDetails />} />
                  <Route path='communications' element={<HelpTicketCommunications />} />
                </Route> */}
              </Route>;

              {/* TEST */}
              {environment !== 'production' && <Route path='/test' element={<TestPage />} />}

              {/* 503 Error Redirect */}
              <Route path='/unavailable' element={<div className='text-center mt-5'>Unavailable</div>} />

              <Route path='*' element={<div className='text-center mt-5'>Page Not Found</div>} />
            </Routes>
          </Suspense>
        </AppWrapper>

        {/* <Footer
            config={{ footer: config.footer, faqPage: config.faqPage, reportFraudPage: config.reportFraudPage }}
            version={VERSION}
            environment={environment}
            googleTranslate={<GoogleTranslate />} /> */}
      </div>
    </ScrollToTop>
  </CustomRouter>
);

AppRouter.propTypes = {
};

export default AppRouter;
